
//
//  Footer-navigation
//  ---------------------------------------------

.footer-navigation {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: calc(2 * @indent__sm) @indent__sm;
    background: @panel__color;
    padding: calc(1.5 * @indent__sm);
    border-radius: @border-radius;

    .mq(m; {
        height: 100%;
        padding: calc(3 * @indent__sm);
    });

    h2 {
        .lib-bluebird-heading(h4);
        margin-block: 0 @indent__sm;

        .mq(m; {
            margin-block-end: calc(1.5 * @indent__sm);
        });
    }

    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;

        > li a {
            display: inline-flex;
            justify-content: space-between;
            gap: @indent__sm;
            width: 100%;
            padding-block: calc(.33 * @indent__sm);
            color: @text__color;

            &::after {
                content: '';
                .svg-arrow-blue-icon();
                .svg-arrow-blue-icon-dims();
                scale: calc(20 / 24);

                .mq(m; {
                    margin-block-start: 4px;
                    scale: 1;
                });
            }
        }
    }
}

.footer-navigation__column {
    min-width: 275px;
    flex-grow: 1;
}
