
//
//  Layered Navigation
//  ---------------------------------------------
body,
html {
    overflow-x: clip;
}

.sections.nav-sections {
    display: none;
}

.filter {
    .block-subtitle {
        display: none;
    }
}

.sidebar-main {
    .mq(l; {
        padding: 50px 0 0;
    });
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
    .page-products .columns {
        padding-top: 70px;
    }
}

@media screen and (max-width: 1023px) {
    .filter.active .filter-title {
        background: @button-secondary__background;
        color: @color-white;

        strong {
            color: @color-white;
            top: 0 !important;
            right: 0 !important;

            &:after {
                background: transparent;
                filter: grayscale(100) brightness(100);
            }
        }
    }
}

.filter-title {
    strong {
        .btn-secondary();

        &:after {
            .svg-filter();
            .svg-filter-dims();
        }

        &:hover,
        &:focus {
            color: @color-white !important;
            background: @button-secondary__background !important;
            outline: 1px solid @button-secondary__background !important;
        }
    }
}

.filter-content {
    padding-top: -50px;
}

.filter-options {
    margin: 0;
}

.filter-options-item {
    .lib-frame();
    border-bottom: 0;
    background: @color-white;
    padding: 40px;
    margin-bottom: 8px;
    border-radius: 16px;

    .filter-options-title {
        .lib-bluebird-heading(h4);
        text-transform: none;
        padding: 0;

        .label {
            width: ~"calc(100% - 30px)";
        }
    }

    .filter-options-content {
        padding: 0;
    }

    .arrow {
        margin-top: 3px;
    }

    .reset {
        display: none;
    }
}

.filter-options {
    .items {
        .item {
            background: @tertiary__color;
            border-radius: 8px;
            padding: 8px;

            input[type=checkbox] {
                display: none;

                &:checked + label {
                    font-weight: normal;
                }
            }

            label {
                width: 100%;
                display: flex;
                align-items: center;
                font-size: @base__font-size;
                font-weight: normal;
                color: @primary__color;
                position: relative;
                padding-left: 45px;
                cursor: pointer;
                min-height: 40px;

                &:before {
                    content: '';
                    display: block;
                    background: white;
                    width: 32px;
                    height: 32px;
                    border: 1px solid @border__color;
                    border-radius: 4px;
                    position: absolute;
                    top: 3px;
                    left: 0;
                }
            }

            input[type=checkbox]:checked + label:before,
            label:hover:before {
                background: url(../svg/src/check-icon-blue.svg) no-repeat center center;
            }
        }
    }
}

.mq(- xxs; {
    .filter-title {
        strong {
            width: 100%;
        }
    }
});

.mq(- m; {
    .filter-options {
        display: block;

        .category-list,
        .filter-options-item
        {
            display: none;
        }
    }

    .filter.active {
        .block-subtitle {
            border-bottom: 0;
            padding: 8px @indent__s @indent__base;
        }

        .filter-title {
            strong {
                top: 4px;
                width: 100%;
                text-align: right;
            }

            &:hover {
                strong:after {
                    color: @secondary__color;
                }
            }
        }

        .filter-options {
            overflow: auto;
            padding: @indent__s;

            .category-list,
            .filter-options-item
            {
                display: block;
            }
        }
    }
});

// quickfix for hiding the results on the search result page
.catalogsearch-result-index .layered-navigation__filter-option-count {
    display: none;
}

// override styling
#layered-filter-block .button-list {
    display: none;
}


.filter-options-content {
    a {
        margin: unset;
        padding: unset;
    }
}

@media screen and (max-width: 1023px) {
    .block-content.filter-content {
        display: none;

        .filter.active & {
            display: block;
        }
    }

    .filter.active .filter-options {
        overflow-x: hidden;
    }
}
