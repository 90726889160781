.category-banner {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 8px;
    margin: 22px 0 30px !important;
}

.category-banner__image {
    padding: 0;
    margin: 0;
    flex: 0 0 ~'calc(33% - 8px)';

    img {
        border-radius: @border-radius;
        width:100%;
        height: 100%;
        object-fit: cover;
    }
}

.category-banner__text {
    background: @secondary__color;
    color: @color-white;
    border-radius: @border-radius;
    padding: 24px;
    flex: 0 0 67%;

    h3 {
        font-size: 2.4rem;
        color: @color-white;
        margin-top: 0;
    }

    p {
        line-height: 1.5;
        color: @color-white;
        font-size: 1.6rem;

        &.category-banner__text-subtitle {
            text-transform: uppercase;
            line-height: 1;
            margin-bottom: 32px;
        }
    }
}

.category-banner__contact {
    padding: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 8px;
    flex: 0 0 100%;

    li {
        height: 50%;
        background: @color-white;
        border-radius: @border-radius;
        display: flex;
        align-items: center;
        padding: 24px;
        gap: 24px;
    }

    .category-banner__contact-icon {
        position: relative;
        flex: 0 0 72px;
        height: 72px;
        background: @tertiary__color;
        border-radius: @border-radius;
        align-items: center;
        justify-content: center;
    }

    .category-banner__contact-icon--phone::after {
        .svg-icon-phone();
        .svg-icon-phone-dims();
    }
    .category-banner__contact-icon--mail::after {
        .svg-icon-mail();
        .svg-icon-mail-dims();
    }

    .category-banner__contact-icon--phone::after,
    .category-banner__contact-icon--mail::after {
        content: '';
        display: block;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    p {
        font-size: 1.6rem;
        line-height: 1.5;
        margin-bottom: 0;

        a {
            font-size: 1.8rem;
            font-weight: bold;
            hyphens: auto;
        }
    }
}

@media screen and (min-width: 768px) {
    .category-banner {
        display: grid;
        grid-template-columns: repeat(9, 1fr);
        grid-template-rows: repeat(2, 1fr);
        grid-column-gap: 8px;
        grid-row-gap: 0;
        margin: 24px 0 32px !important;
    }

    .category-banner__image {
        grid-area: ~'1 / 1 / 3 / 3';
    }

    .category-banner__text {
        grid-area: ~'1 / 3 / 3 / 7';
        padding: 40px;

        h3 {
            font-size: 3.2rem;
            margin-bottom: 24px;
        }

        p {
            font-size: 2.0rem;

            &.category-banner__text-subtitle {
                display: none;
            }
        }
    }

    .category-banner__contact {
        grid-area: ~'1 / 7 / 3 / 10';

        p {
            font-size: 2rem;
        }
    }
}

@media screen and (min-width: 1024px) and (max-width: 1340px) {
    .category-banner {
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        grid-template-rows: repeat(3, 1fr);
        grid-column-gap: 8px;
        grid-row-gap: 8px;
    }
    .category-banner__image {
        grid-area: ~'1 / 1 / 3 / 3';
    }
    .category-banner__text {
        grid-area: ~'1 / 3 / 3 / 7';
    }
    .category-banner__contact {
        grid-area: ~'3 / 1 / 4 / 7';
    }
}
