
//
//  Footer Columns
//  ---------------------------------------------

.page-footer{
    background: @tertiary__color;
}

.footer {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: @layout__max-width;
    padding: 0 @layout__width-xs-indent;
    margin-inline: auto;
    box-sizing: border-box;

    .mq(m; {
        flex-direction: row;
        align-items: flex-start;
        padding: 0 @layout-indent__width;
    });

    [data-content-type='row'][data-appearance='contained'] {
        padding: 0;
    }
}

.footer__column--navigation {
    align-self: stretch;
}

.footer__column--contact {
    display: flex;
    flex-direction: column;
    gap: @indent__xs;

    .mq(l; {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto auto auto;
    });

    .mq(- m; {
        margin-block-start: @indent__xs;
    });
}

.footer__legal {
    margin-top: auto;
    padding: calc(3 * @indent__sm) @layout__width-xs-indent 0;
    font-size: @font-size__s;

    p {
        opacity: .4;
        margin: 0;
    }

    .mq(m; {
        padding: calc(4 * @indent__sm) @layout-indent__width 0;
        font-size: @font-size__m;
    });

    .mq(l; {
        grid-column-start: 1;
        grid-column-end: span 2;
        grid-row-start: 3;
        grid-row-end: span 1;
    });
}

.footer__legal-grid {
    > div {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: calc(.25 * @indent__sm);
        margin-block-end: @indent__sm;

        .mq(m; {
            gap: calc(.5 * @indent__sm);
        });
    }

    img {
        width: 36px;
        height: auto;
        margin-right: calc(.5 * @indent__sm);
    }
}
