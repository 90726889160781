
//
//  Footer-contact
//  ---------------------------------------------

.footer-contact {
    display: flex;
    flex-direction: row;
    gap: calc(1.5 * @indent__sm);
    background: @panel__color;
    padding: calc(1.5 * @indent__sm);
    border-radius: @border-radius;

    > div {
        flex-grow: 1;
    }

    figure {
        flex-shrink: 0;
        width: 72px;
        aspect-ratio: 1;
        border-radius: 50%;
        overflow: hidden;

        img {
            object-fit: cover;
            width: 100%;
            height: 100%;
        }
    }
}

.footer-contact--secondary {
    background: @panel__color--secondary;
    color: @text__color--primary;
}

.footer-contact__grid {
    container-type: inline-size;
    display: flex;
    align-items: flex-start;
    width: 100%;
    gap: calc(1.5 * @indent__sm);

    .mq(- m; {
        gap: @indent__sm;
    });
}

.footer-contact__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: calc(5 * @indent__sm);
    height: calc(5 * @indent__sm);
    border-radius: @border-radius--md;
    background: @tertiary__color;

    .mq(- m; {
        width: calc(4 * @indent__sm);
        height: calc(4 * @indent__sm);
    });

    &::before {
        .mq(- m; {
            scale: .8;
        });
    }
}

.footer-contact__icon--map {
    &::before {
        content: '';
        .svg-pin-icon();
        .svg-pin-icon-dims();
    }
}

.footer-contact__icon--phone {
    &::before {
        content: '';
        .svg-phone-icon();
        .svg-phone-icon-dims();
    }
}

.footer-contact__icon--mail {
    &::before {
        content: '';
        .svg-mail-icon();
        .svg-mail-icon-dims();
    }
}

.footer-contact__content {
    display: flex;
    flex-direction: column;
    gap: calc(.25 * @indent__sm);
}

.footer-contact__title:is(p) {
    margin-block: calc(.25 * @indent__sm) 0;
}

.footer-contact__link {
    font-size: @font-size__l;
    font-weight: 700;
    text-decoration: underline;
    text-underline-offset: .8rem;
    hyphens: auto;

    .mq(- m; {
        font-size: @font-size__m;
    });
}

@container (max-width: 250px) {
    .footer-contact {
        figure {
            display: none;
        }
    }

    .footer-contact__icon {
        display: none;
    }
}
