.svg-common() {
	background: url("../svg/dist/css/css-sprite.svg") no-repeat;
}

.svg-account-icon() {
.svg-common();
background-position: 0 0;
}

.svg-account-icon-dims() {
	width: 24px;
height: 24px;
}

.svg-arrow-blue-icon() {
.svg-common();
background-position: 0 2.6058631921824102%;
}

.svg-arrow-blue-icon-dims() {
	width: 24px;
height: 24px;
}

.svg-arrow-down-icon() {
.svg-common();
background-position: 0 5.194805194805195%;
}

.svg-arrow-down-icon-dims() {
	width: 18px;
height: 21px;
}

.svg-arrow-down-icon-blue() {
.svg-common();
background-position: 0 7.467532467532467%;
}

.svg-arrow-down-icon-blue-dims() {
	width: 18px;
height: 21px;
}

.svg-arrow-icon() {
.svg-common();
background-position: 0 9.594882729211088%;
}

.svg-arrow-icon-dims() {
	width: 10px;
height: 7px;
}

.svg-arrow-next-icon() {
.svg-common();
background-position: 0 10.396570203644158%;
}

.svg-arrow-next-icon-dims() {
	width: 14px;
height: 12px;
}

.svg-arrow-next-icon-blue() {
.svg-common();
background-position: 0 11.682743837084674%;
}

.svg-arrow-next-icon-blue-dims() {
	width: 14px;
height: 12px;
}

.svg-arrow-right-icon-blue() {
.svg-common();
background-position: 0 12.913553895410885%;
}

.svg-arrow-right-icon-blue-dims() {
	width: 6px;
height: 8px;
}

.svg-arrow-white-icon() {
.svg-common();
background-position: 0 14.006514657980455%;
}

.svg-arrow-white-icon-dims() {
	width: 24px;
height: 24px;
}

.svg-cart-add-icon-blue() {
.svg-common();
background-position: 0 16.612377850162865%;
}

.svg-cart-add-icon-blue-dims() {
	width: 24px;
height: 24px;
}

.svg-cart-add-icon-white() {
.svg-common();
background-position: 0 19.218241042345277%;
}

.svg-cart-add-icon-white-dims() {
	width: 24px;
height: 24px;
}

.svg-cart-icon() {
.svg-common();
background-position: 0 21.824104234527688%;
}

.svg-cart-icon-dims() {
	width: 24px;
height: 24px;
}

.svg-cart-icon-white() {
.svg-common();
background-position: 0 24.37703141928494%;
}

.svg-cart-icon-white-dims() {
	width: 34px;
height: 22px;
}

.svg-check-icon-blue() {
.svg-common();
background-position: 0 26.41711229946524%;
}

.svg-check-icon-blue-dims() {
	width: 13px;
height: 10px;
}

.svg-check-icon-green() {
.svg-common();
background-position: 0 27.486631016042782%;
}

.svg-check-icon-green-dims() {
	width: 13px;
height: 10px;
}

.svg-check-icon-white() {
.svg-common();
background-position: 0 28.617363344051448%;
}

.svg-check-icon-white-dims() {
	width: 12px;
height: 12px;
}

.svg-chevron-left() {
.svg-common();
background-position: 0 30.425299890948747%;
}

.svg-chevron-left-dims() {
	width: 17px;
height: 28px;
}

.svg-chevron-left-blue() {
.svg-common();
background-position: 0 33.47873500545256%;
}

.svg-chevron-left-blue-dims() {
	width: 17px;
height: 28px;
}

.svg-chevron-right() {
.svg-common();
background-position: 0 36.532170119956376%;
}

.svg-chevron-right-dims() {
	width: 17px;
height: 28px;
}

.svg-chevron-right-blue() {
.svg-common();
background-position: 0 39.585605234460196%;
}

.svg-chevron-right-blue-dims() {
	width: 17px;
height: 28px;
}

.svg-compare-icon() {
.svg-common();
background-position: 0 42.08826695371367%;
}

.svg-compare-icon-dims() {
	width: 21px;
height: 16px;
}

.svg-compare-icon-lightblue() {
.svg-common();
background-position: 0 43.81054897739505%;
}

.svg-compare-icon-lightblue-dims() {
	width: 21px;
height: 16px;
}

.svg-cross-icon() {
.svg-common();
background-position: 0 45.928338762214985%;
}

.svg-cross-icon-dims() {
	width: 24px;
height: 24px;
}

.svg-delete-icon() {
.svg-common();
background-position: 0 48.32432432432432%;
}

.svg-delete-icon-dims() {
	width: 20px;
height: 20px;
}

.svg-delete-icon-blue() {
.svg-common();
background-position: 0 50.486486486486484%;
}

.svg-delete-icon-blue-dims() {
	width: 20px;
height: 20px;
}

.svg-delete-icon-red() {
.svg-common();
background-position: 0 52.0855614973262%;
}

.svg-delete-icon-red-dims() {
	width: 13px;
height: 10px;
}

.svg-filter() {
.svg-common();
background-position: 0 53.96308360477742%;
}

.svg-filter-dims() {
	width: 24px;
height: 24px;
}

.svg-icon-mail() {
.svg-common();
background-position: 0 56.95856564993987%;
}

.svg-icon-mail-dims() {
	width: 32.8px;
height: 30.3px;
}

.svg-icon-phone() {
.svg-common();
background-position: 0 60.526315789473685%;
}

.svg-icon-phone-dims() {
	width: 34px;
height: 33px;
}

.svg-list-icon() {
.svg-common();
background-position: 0 63.72549019607843%;
}

.svg-list-icon-dims() {
	width: 19px;
height: 27px;
}

.svg-long-arrow-right-icon() {
.svg-common();
background-position: 0 65.45454545454545%;
}

.svg-long-arrow-right-icon-dims() {
	width: 12px;
height: 10px;
}

.svg-long-arrow-right-icon-lightblue() {
.svg-common();
background-position: 0 66.524064171123%;
}

.svg-long-arrow-right-icon-lightblue-dims() {
	width: 12px;
height: 10px;
}

.svg-mail-icon() {
.svg-common();
background-position: 0 69.8342541436464%;
}

.svg-mail-icon-dims() {
	width: 40px;
height: 40px;
}

.svg-menu-icon-blue() {
.svg-common();
background-position: 0 72.64864864864865%;
}

.svg-menu-icon-blue-dims() {
	width: 28px;
height: 20px;
}

.svg-menu-icon-darkblue() {
.svg-common();
background-position: 0 74.8108108108108%;
}

.svg-menu-icon-darkblue-dims() {
	width: 28px;
height: 20px;
}

.svg-menu-icon-white() {
.svg-common();
background-position: 0 77.30727470141152%;
}

.svg-menu-icon-white-dims() {
	width: 24px;
height: 24px;
}

.svg-minus-icon() {
.svg-common();
background-position: 0 79.22497308934338%;
}

.svg-minus-icon-dims() {
	width: 16px;
height: 16px;
}

.svg-phone-icon() {
.svg-common();
background-position: 0 82.54665203073546%;
}

.svg-phone-icon-dims() {
	width: 34px;
height: 34px;
}

.svg-pin-icon() {
.svg-common();
background-position: 0 86.85082872928177%;
}

.svg-pin-icon-dims() {
	width: 40px;
height: 40px;
}

.svg-plus-icon() {
.svg-common();
background-position: 0 88.91280947255113%;
}

.svg-plus-icon-dims() {
	width: 16px;
height: 16px;
}

.svg-remove-icon() {
.svg-common();
background-position: 0 90.2465166130761%;
}

.svg-remove-icon-dims() {
	width: 12px;
height: 12px;
}

.svg-search-icon() {
.svg-common();
background-position: 0 93.02832244008715%;
}

.svg-search-icon-dims() {
	width: 26px;
height: 27px;
}

.svg-search-icon-white() {
.svg-common();
background-position: 0 95.44962080173347%;
}

.svg-search-icon-white-dims() {
	width: 22px;
height: 22px;
}

.svg-trash-icon() {
.svg-common();
background-position: 0 97.72727272727273%;
}

.svg-trash-icon-dims() {
	width: 18px;
height: 21px;
}

.svg-trash-icon-darkblue() {
.svg-common();
background-position: 0 100%;
}

.svg-trash-icon-darkblue-dims() {
	width: 18px;
height: 21px;
}

